<template>
    <TabInvoices />
</template>

<script>
    import TabInvoices from '@/components/Invoices/TabInvoices.vue'

    export default {
        title: 'factures.title',
        name: 'Factures',
        components: {
            TabInvoices
        }
    }
</script>
